import React, { FC, useState, useEffect } from "react";
import axios from "axios";

import {
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  value: string;
}

export const RequestModal: FC<IProps> = ({ isOpen, onClose, value }) => {
  const [room, setRoom] = useState("1");
  const [name, setName] = useState("");
  const [count, setCount] = useState<string | number>("");
  const [phone, setPhone] = useState<string | number>("");
  const [dateIn, setDateIn] = useState("2022-12-20");
  const [dateOut, setDateOut] = useState("2023-01-05");
  const [email, setEmail] = useState<string>("");
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    if (value) {
      setRoom(value);
    }
  }, [value]);

  const sendEmailHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": " multipart/form-data",
        },
      };

      let formData = new FormData();
      formData.append("text", "Hello mail");

      const response = await axios.post("/sendmail.php", formData, config);
      console.log(response);
    } catch (error) {
      console.log("emailError>>>", error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="4xl"
        isCentered
        motionPreset="slideInRight"
        // scrollBehavior="inside"
      >
        <ModalOverlay backdropFilter="blur(8px)" />

        <ModalContent border="1px solid #282828" color="#282828" p={[4, 4, 8]}>
          <ModalHeader p={[0, 0, 4]}>
            <Text my={[0, 0, 2]} fontSize={["md", "md", "xl"]}>
              Бронь номера
            </Text>
            <Text my={[0, 0, 2]} fontSize={["xl", "xl", "3xl"]}>
              Забронировать номер
            </Text>
            <Text my={[0, 0, 2]} fontSize={["md", "md", "xl"]}>
              Мы свяжемся с вами для уточнения деталей заказа
            </Text>
          </ModalHeader>
          <ModalCloseButton size="lg" />

          <ModalBody p={[0, 0, 4]}>
            <Flex
              direction={["column", "column", "row"]}
              justifyContent="space-between"
              my={[2, 2, 4]}
            >
              <Select
                value={room}
                onChange={(e) => setRoom(e.target.value)}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                borderRadius="0px"
                border="1px solid #282828"
                my={[4, 4, 0]}
              >
                <option value="1">Стандартный одноместный</option>
                <option value="2">Стандартный двухместный</option>
                <option value="3">Номер ЛЮКС</option>
              </Select>

              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                placeholder="ФИО"
                type="text"
                borderRadius="0px"
                border="1px solid #282828"
              />
            </Flex>

            <Flex
              direction={["column", "column", "row"]}
              justifyContent="space-between"
              my={[2, 2, 4]}
            >
              <Input
                value={count}
                onChange={(e) => setCount(e.target.value)}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                my={[4, 4, 0]}
                placeholder="Количество гостей"
                type="text"
                borderRadius="0px"
                border="1px solid #282828"
              />

              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                placeholder="+7 999 888 77 55"
                type="text"
                borderRadius="0px"
                border="1px solid #282828"
              />
            </Flex>

            <Flex
              direction={["column", "column", "row"]}
              justifyContent="space-between"
              my={[2, 2, 4]}
            >
              <Flex
                width={["100%", "100%", "47%"]}
                justifyContent="space-between"
              >
                <Input
                  value={dateIn}
                  onChange={(e) => setDateIn(e.target.value)}
                  width="45%"
                  height={["50px", "50px", "60px"]}
                  placeholder="Дата заезда"
                  type="date"
                  borderRadius="0px"
                  border="1px solid #282828"
                  my={[4, 4, 0]}
                />

                <Input
                  value={dateOut}
                  onChange={(e) => setDateOut(e.target.value)}
                  width="45%"
                  height={["50px", "50px", "60px"]}
                  placeholder="Дата выезда"
                  type="date"
                  borderRadius="0px"
                  border="1px solid #282828"
                  my={[4, 4, 0]}
                />
              </Flex>

              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                placeholder="Email"
                type="email"
                borderRadius="0px"
                border="1px solid #282828"
              />
            </Flex>

            <Flex
              direction={["column", "column", "row"]}
              justifyContent="space-between"
              alignItems="center"
              my={[2, 2, 4]}
            >
              <Flex
                width={["100%", "100%", "47%"]}
                justifyContent="space-between"
              >
                <Checkbox
                  checked={agreement}
                  onChange={(e) => setAgreement(e.target.checked)}
                  colorScheme="green"
                  fontSize={["sm", "md", "lg"]}
                  my={[2, 2, 0]}
                >
                  Нажимая кнопку «Отправить запрос», вы соглашаетесь с нашей
                  политикой обработки персональных данных
                </Checkbox>
              </Flex>

              <Button
                onClick={() => sendEmailHandler()}
                isDisabled={!agreement}
                width={["100%", "100%", "47%"]}
                height={["50px", "50px", "60px"]}
                borderRadius="0px"
                colorScheme="teal"
              >
                ОТПРАВИТЬ ЗАПРОС
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
