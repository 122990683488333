import React, { useState } from "react";
import styles from "./Home.module.scss";

import { Link } from "react-scroll";

import { motion } from "framer-motion";

import About from "./about/About";
import Rooms from "./rooms/Rooms";
import Advantages from "./advantages/Advantages";
import Gallery from "./gallery/Gallery";
import Contacts from "./contacts/Contacts";
import { Meta } from "../utils/meta/Meta";

import bgImage from "../assets/img/main-block-bg3.jpg";
import bgImageMini from "../assets/img/main-block-bg2mini.png";

import { RequestModal } from "../components/requestModal/RequestModal";
import { RiMenu5Fill } from "react-icons/ri";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";

export const routes = [
  { id: 1, title: "О нас", path: "about" },
  { id: 2, title: "Номерной фонд", path: "rooms" },
  { id: 3, title: "Услуги", path: "advantages" },
  { id: 4, title: "Галерея", path: "gallery" },
  { id: 5, title: "Контакты", path: "contacts" },
];

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");
  const [isSmallerThan500] = useMediaQuery("(max-width: 500px)");

  // Framer Motion
  const whileHover = {
    scale: 1.1,
    transition: { duration: 0.1 },
    color: "#92b775",
  };
  const whileTap = { scale: 1 };

  const buttonAnimation = {
    hidden: {
      y: -100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 2 },
    }),
  };

  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 2 },
    }),
  };

  const titleAnimation = {
    hidden: {
      x: +300,
      opacity: 0,
    },
    visible: (custom: number) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 1 },
    }),
  };

  return (
    <>
      <Meta
        title="Гостевой дом в поселке Поспелиха"
        description="Горный Алтай является одной из самых мистических и экологически чистых территорий нашей планеты! Прикоснитесь к этой первозданной природе в нашем доме!"
      />

      <div className={styles.header}>
        <div className={styles.container}>
          <motion.h3 whileHover={whileHover} className={styles.logo}>
            Гостевой Дом
          </motion.h3>

          {isSmallerThan900 ? (
            <Button variant="ghost" onClick={() => setOpenDrawer(true)}>
              <RiMenu5Fill size={40} />
            </Button>
          ) : (
            <div className={styles.navigation}>
              {routes.map((route, index) => (
                <Link
                  key={route.id}
                  to={route.path}
                  spy={true}
                  smooth={true}
                  duration={1200}
                  className={styles.link}
                >
                  <motion.p whileHover={whileHover} whileTap={whileTap}>
                    {route.title}
                  </motion.p>
                </Link>
              ))}
            </div>
          )}

          <Drawer
            isOpen={openDrawer}
            placement="right"
            onClose={() => setOpenDrawer(false)}
            size="xs"
            closeOnEsc={true}
            closeOnOverlayClick={true}
          >
            <DrawerOverlay backdropFilter="blur(10px)" />

            <DrawerContent bg="#92B775" h="400px" w="200px" mt={4} mr={4}>
              <DrawerCloseButton
                color="#FFF"
                onClick={() => setOpenDrawer(false)}
              ></DrawerCloseButton>
              <DrawerHeader></DrawerHeader>

              <DrawerBody>
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  ml={6}
                >
                  {routes.map((route, index) => (
                    <Link
                      key={route.id}
                      to={route.path}
                      spy={true}
                      smooth={true}
                      duration={1000}
                      className={styles.drawer_link}
                      onClick={() => setOpenDrawer(false)}
                    >
                      <motion.p whileHover={whileHover} whileTap={whileTap}>
                        {route.title}
                      </motion.p>
                    </Link>
                  ))}
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </div>
      </div>

      <div className={styles.main}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3, once: true }}
          className={styles.section}
        >
          <div className={styles.title__container}>
            <div className={styles.title__box}>
              <motion.p
                custom={0.5}
                variants={titleAnimation}
                className={styles.title}
              >
                Гостевой дом
              </motion.p>
              <motion.h5
                custom={1}
                variants={titleAnimation}
                className={styles.title}
              >
                в поселке Поспелиха
              </motion.h5>
            </div>

            <div className={styles.description__box}>
              <motion.h3
                custom={0.7}
                variants={textAnimation}
                className={styles.description}
              >
                Уютные гостевые номера
              </motion.h3>

              <motion.h3
                custom={0.8}
                variants={textAnimation}
                className={styles.description}
              >
                от стандарта до люкс всего
              </motion.h3>

              <motion.h3
                custom={0.9}
                variants={textAnimation}
                className={styles.description}
              >
                от 1500 рублей в сутки
              </motion.h3>
            </div>
          </div>

          <img
            src={isSmallerThan500 ? bgImageMini : bgImage}
            alt="дом"
            className={styles.bg_image}
          />

          <motion.div
            style={{ textAlign: "center" }}
            custom={1.1}
            variants={buttonAnimation}
          >
            <Button
              onClick={() => onOpen()}
              minW={["300px", "300px", "350px"]}
              h="60px"
              color="#FFF"
              fontSize="16px"
              lineHeight="22px"
              bgColor="#133115"
              boxShadow={"0px 20px 80px rgba(19, 49, 21, 0.53)"}
              rounded="0px"
            >
              ОТПРАВИТЬ ЗАЯВКУ
            </Button>
          </motion.div>

          <RequestModal isOpen={isOpen} onClose={onClose} value="1" />
        </motion.div>
      </div>

      {/* ................СТРАНИЦЫ.................... */}

      <Flex
        direction="column"
        alignItems="center"
        width={["90%", "90%", "90%", "80%"]}
        maxW="1200px"
        id="about"
      >
        <About />
      </Flex>

      <Flex
        direction="column"
        alignItems="center"
        width={["90%", "90%", "90%", "80%"]}
        maxW="1200px"
        id="rooms"
      >
        <Rooms />
      </Flex>

      <Flex
        direction="column"
        alignItems="center"
        width={["90%"]}
        maxW="1400px"
        id="advantages"
      >
        <Advantages />
      </Flex>

      <Flex w="100%" maxW="2000px" id="gallery">
        <Gallery />
      </Flex>

      <Flex w="100%" maxW="2000px" id="contacts">
        <Contacts />
      </Flex>
    </>
  );
};

export default Home;
